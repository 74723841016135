<template>
	<button
		:id="id"
		type="button"
		role="switch"
		class="my-auto inline-flex h-min items-center gap-2 rounded-full transition-shadow focus:outline-none focus:ring-2 focus:ring-offset-2"
		:aria-checked="modelValue"
		:class="[
			modelValue ? 'focus:ring-green-600' : 'focus:ring-red-500',
			{ dark },
			disabled
				? 'cursor-not-allowed text-gray-500'
				: `cursor-pointer ${modelValue ? 'text-green-600' : 'text-red-600'}`,
		]"
		:disabled="disabled"
		@click.stop="switched"
	>
		<span v-if="$slots.prefix" class="text-gray-800 dark:text-gray-100">
			<slot name="prefix"></slot>
		</span>
		<span
			class="relative inline-flex h-5 w-9 shrink-0 items-center rounded-full shadow-inner shadow-gray-900/25 transition-colors duration-200 ease-in-out"
			:class="[disabled ? 'bg-gray-500' : `${modelValue ? 'bg-green-600' : 'bg-red-600'}`]"
		>
			<span class="sr-only">{{ a11yLabel || visibleLabel }}</span>
			<span
				class="pointer-events-none relative inline-flex h-4 w-4 transform items-center justify-center rounded-full shadow ring-0 transition duration-200 ease-in-out"
				:class="[
					modelValue ? 'translate-x-[1.1rem]' : 'translate-x-0.5',
					disabled ? 'bg-gray-300' : 'bg-white',
				]"
			>
				<FAIcon
					aria-hidden="true"
					:icon="modelValue ? 'fa-check' : 'fa-xmark'"
					class="h-3 w-3"
					:class="[disabled && 'text-gray-900']"
				/>
			</span>
		</span>
		<span v-if="$slots.default" class="text-gray-800 dark:text-gray-200">
			<slot name="default"></slot>
		</span>
		<span v-else-if="visibleLabel" class="text-gray-800 dark:text-gray-200">{{
			visibleLabel
		}}</span>
	</button>
</template>

<script setup>
const emit = defineEmits(['update:modelValue']);
const props = defineProps({
	modelValue: { type: Boolean, default: false },
	a11yLabel: { type: String, default: '' },
	disabled: { type: Boolean, default: false },
	visibleLabel: { type: String, default: null },
	dark: { type: Boolean, default: false },
	id: { type: String, required: false, default: undefined },
});

function switched() {
	emit('update:modelValue', !props.modelValue);
}
</script>

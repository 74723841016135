<template>
	<Form
		v-slot="{ meta, handleSubmit }"
		class="rounded-lg bg-white p-4 text-gray-700 dark:bg-gray-800 dark:text-gray-300"
		tag="div"
	>
		<h3 class="pb-4 text-2xl font-semibold leading-none text-gray-700 dark:text-gray-100">
			Search Email Logs (last 7 days)
		</h3>
		<form class="grid grid-cols-2 gap-4" @submit.prevent="handleSubmit(getEmailSearchResults)">
			<Field v-slot="{ errors, field }" :rules="{ required: !toEmail }" name="from">
				<TextFieldInput
					v-model="fromEmail"
					label="From Email"
					placeholder="from email"
					v-bind="field"
				>
					<template v-if="errors.length > 0" #message>
						You must fill in at least one field
					</template>
				</TextFieldInput>
			</Field>

			<Field v-slot="{ errors, field }" :rules="{ required: !fromEmail }" name="to">
				<TextFieldInput
					v-model="toEmail"
					label="To Email"
					placeholder="to email"
					v-bind="field"
				>
					<template v-if="errors.length > 0" #message>
						You must fill in at least one field
					</template>
				</TextFieldInput>
			</Field>

			<BaseButton
				:disabled="!meta.dirty || !meta.valid"
				class="col-span-2 w-1/4 justify-self-end"
				type="submit"
			>
				Search
			</BaseButton>
			<BaseButton
				:disabled="!showDownload"
				:href="showDownload ? downloadApiUrl : null"
				class="col-span-2 w-1/4 justify-self-end"
			>
				Download CSV
			</BaseButton>
		</form>
	</Form>

	<div v-if="loading" class="mt-4 py-16 rounded-lg bg-white p-4 dark:bg-gray-900">
		<LoadingSpinner text="Loading search results..." />
	</div>

	<SortableTable
		v-else
		:headers="headers"
		:items="parsedEmails"
		title="Search Results"
		item-unique-key="arrival"
		show-pagination
		show-search
		dense
		show-title
		class="mt-4 shrink grow-0 rounded-lg bg-white dark:bg-gray-800 p-4"
		:expand-all="false"
	>
		<template #item.arrival="{ arrival }">
			{{ format(new Date(arrival), 'MMM dd, yyy h:mm:ss a') }}
		</template>
		<template #item.from="{ from }">
			{{ from }}
		</template>
		<template #item.to="{ to }">
			{{ to }}
		</template>
		<template #item.delivery_response="{ delivery_response }">
			{{ delivery_response }}
		</template>
		<template #item.denial_msg="{ denial_msg }">
			{{ shorthandDenialMessage(denial_msg) }}
		</template>

		<template #item_expanded="{ denial_msg }">
			<div class="p-4">
				<span v-if="!denial_msg"> null </span>
				<pre
					v-else
					lang="json"
				>{{ formatDenialMessage(denial_msg) }}</pre>
			</div>
		</template>
	</SortableTable>
</template>

<script setup>
import { ref, computed } from 'vue';
import { Form, Field } from 'vee-validate';

import { format } from 'date-fns';

import { useApi } from '@/composables/useApi';

import SortableTable from '@/components/ui/SortableTable.vue';
import TextFieldInput from '@/components/ui/TextFieldInput.vue';
import BaseButton from '@/components/ui/BaseButton.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';

const headers = [
	{ text: 'Arrival', value: 'arrival', sortable: true, wrap: false },
	{ text: 'From', value: 'from', filterable: true, sortable: true, wrap: false },
	{ text: 'To', value: 'to', filterable: true, sortable: true, wrap: false },
	{ text: 'Delivery Response', value: 'delivery_response', truncate: true },
	{ text: 'Denial Message', value: 'denial_msg', truncate: true },
];

const loading = ref(false);
const fromEmail = ref(null);
const toEmail = ref(null);
const emails = ref([]);

const parsedEmails = computed(() =>
	emails.value.map(email => ({ ...email, expandable: Boolean(email.denial_msg) }))
);

const showDownload = computed(() => {
	return !loading.value && emails.value?.length;
});

const searchApiUrl = computed(() => {
	const params = {};

	if (toEmail.value !== null) {
		params.to = toEmail.value;
	}

	if (fromEmail.value !== null) {
		params.from = fromEmail.value;
	}
	return `api/agents/search_emails/?${new URLSearchParams(params)}`;
});

const downloadApiUrl = computed(() => {
	return `/${searchApiUrl.value}&format=csv`;
});

async function getEmailSearchResults() {
	emails.value = [];
	loading.value = true;

	try {
		const result = await useApi(searchApiUrl.value, {
			message: 'There was a issue with pulling your email search. Please try again later!',
		}).json();

		emails.value = result.data.value?.emails;
	} catch (error) {
		console.error(error);
	} finally {
		loading.value = false;
	}
}

function shorthandDenialMessage(message) {
	if (!message) {
		return null;
	}

	try {
		const jsonData = JSON.parse(message);
		return jsonData.msg;
	} catch (error) {
		console.warn(error);
		// if parse fails, fall back to the original string
		return message;
	}
}

function formatDenialMessage(message) {
	// message can be a stringified JSON object or a plain string
	try {
		return JSON.parse(message);
	} catch (error) {
		console.error(error);
		// if parse fails, fall back to the original string
		return message;
	}
}
</script>

<style module lang="scss">
.table {
}
.body {
	overflow: auto;
}
</style>

<template>
	<fieldset :class="{ dark: dark }">
		<legend
			class="mb-0.5 whitespace-nowrap text-xs text-gray-600 dark:text-gray-200"
			:class="{ 'sr-only': !showLegend }"
		>
			{{ legend }}
		</legend>
		<div
			class="relative z-0 inline-flex flex-col gap-1.5 rounded-lg bg-gray-200 p-1.5 text-gray-800 dark:bg-gray-700 dark:text-gray-50 sm:flex-row"
		>
			<button
				v-for="button in buttons"
				:key="button.value"
				type="button"
				class="button"
				:class="
					button.value === modelValue
						? 'bg-gray-800 text-gray-50 dark:bg-gray-50 dark:text-gray-700'
						: 'hover:bg-white hover:ring-white dark:hover:bg-gray-600 dark:hover:ring-gray-600'
				"
				:aria-pressed="button.value === modelValue"
				:aria-label="button.aria"
				@click="handleChange(button.value)"
			>
				<template v-if="$slots.default">
					<slot name="default" v-bind="{ button }" />
				</template>
				<div v-else>
					<FAIcon v-if="button.icon" :icon="`fa-${button.icon}`" class="pr-2" />
					<span>{{ button.text }}</span>
				</div>
			</button>
		</div>
	</fieldset>
</template>

<script setup>
const emit = defineEmits(['update:modelValue', 'click']);
defineProps({
	modelValue: { type: [String, Boolean], required: true },
	buttons: { type: Array, required: true },
	legend: { type: String, required: false, default: '' },
	dark: { type: Boolean, required: false, default: false },
	showLegend: { type: Boolean, required: false, default: false },
});
function handleChange(value) {
	emit('update:modelValue', value);
	emit('click', value);
}
</script>
<style>
.button {
	@apply relative inline-flex grow items-center justify-center rounded px-2 py-1 text-sm font-medium uppercase ring-0 ring-transparent;
	@apply transition hover:ring-2;
	@apply outline-none focus-visible:underline focus-visible:ring-2 focus-visible:ring-gray-900 focus-visible:ring-offset-2;
}
</style>

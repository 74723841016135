<template>
	<div
		class="flex w-full grow-0 flex-wrap-reverse items-center justify-end gap-x-4 gap-y-2 @container"
	>
		<span
			v-if="itemCount > 0"
			class="mr-auto hidden whitespace-nowrap pl-2 text-sm text-gray-900 @3xl:block dark:text-white"
		>
			{{ startIndex + 1 }}-{{ Math.min(endIndex, itemCount) }}
			<span>of</span>
			{{ itemCount }}
		</span>

		<div v-if="rowsPerPageOptions.length > 1" class="flex items-center gap-1">
			<span class="whitespace-nowrap text-xs font-semibold" aria-hidden="true">
				Rows per page:
			</span>
			<BaseSelect
				:id="`table-paginator-${uid}`"
				:items="rowsPerPageOptions"
				:show-default-option="false"
				:model-value="rowsPerPage"
				label="Rows per page"
				class="!-mb-4 !min-w-14 grow-0"
				outlined
				@update:model-value="handleRowCountChange"
			/>
		</div>

		<div v-if="tooManyPages" class="flex items-center gap-1">
			<label for="jump-to-page" class="whitespace-nowrap text-xs font-semibold">
				Jump to page:
			</label>
			<input
				id="jump-to-page"
				v-model.number="jumpToPage"
				class="h-9 w-14 rounded border border-gray-500 bg-white text-center text-sm outline-none ring-orange-500 focus-visible:ring-2 dark:bg-gray-800 dark:ring-orange-400"
				type="number"
				placeholder="0"
				@input="$event.target.value = $event.target.value.replace(/[^0-9]/g, '')"
				@keyup.enter="currentPage = jumpToPage || currentPage"
				@blur="currentPage = jumpToPage || currentPage"
				@click="$event.target.select()"
			/>
		</div>

		<nav
			v-if="totalPages > 1"
			class="grid grid-flow-col gap-0.5 text-sm font-semibold"
			role="navigation"
			aria-label="Pagination"
		>
			<button
				type="button"
				class="next-previous-button"
				aria-label="Previous page"
				:aria-disabled="currentPage === 1"
				@click="previousPage"
			>
				<FAIcon icon="chevron-left" />
			</button>

			<button
				type="button"
				class="page-button"
				:class="{ active: currentPage === 1 }"
				:aria-current="currentPage === 1 ? 'page' : undefined"
				aria-label="page 1 (first page)"
				@click="currentPage = 1"
			>
				1
			</button>

			<div v-if="showPreviousEllipsis" class="grid h-9 w-6 place-items-center">...</div>

			<button
				v-for="page in visiblePageButtons"
				:key="page"
				type="button"
				class="page-button"
				:class="{ active: currentPage === page }"
				:aria-current="currentPage === page ? 'page' : undefined"
				:aria-label="`page ${page}`"
				@click="currentPage = page"
			>
				{{ page }}
			</button>

			<div v-if="showNextEllipsis" class="grid h-9 w-6 place-items-center">...</div>

			<button
				type="button"
				class="page-button"
				:class="{ active: currentPage === totalPages }"
				:aria-current="currentPage === totalPages ? 'page' : undefined"
				:aria-label="`page ${totalPages} (last page)`"
				@click="currentPage = totalPages"
			>
				{{ totalPages }}
			</button>

			<button
				type="button"
				class="next-previous-button"
				aria-label="Next page"
				:aria-disabled="currentPage === totalPages"
				@click="nextPage"
			>
				<FAIcon icon="chevron-right" />
			</button>
		</nav>
	</div>
</template>
<script setup>
import { ref, computed, watchEffect, watch } from 'vue';

import BaseSelect from '@/components/ui/BaseSelect.vue';

const emit = defineEmits(['update:rows-per-page', 'update:start-index', 'update:end-index']);

const props = defineProps({
	rowsPerPage: { type: Number, required: true },
	itemCount: { type: Number, required: true },
	startIndex: { type: Number, required: true },
	endIndex: { type: Number, required: true },

	title: { type: String, required: true },
	// number of pages to show on each side of the current page
	// eg.   1 ... 5 6 7 ... 11    is 1 on each side
	// eg. 1 ... 4 5 6 7 8 ... 11  is 2 on each side
	range: { type: Number, default: 2 },
});
const VALID_ROWS_PER_PAGE = [5, 10, 15, 20, 25, 30, 40, 50, 75, 100, 150, 200, 250];
const uid = crypto.randomUUID();

const currentPage = ref(1);
const jumpToPage = ref(currentPage.value);

const totalPages = computed(() => {
	if (props.itemCount === 0) {
		return 0;
	}
	return Math.ceil(props.itemCount / props.rowsPerPage);
});

// + 5 is to account for the (1) current page slot, the (2) ellipsis slots,
// and the (2) first and last page slots
const tooManyPages = computed(() => totalPages.value > props.range * 2 + 5);

const showPreviousEllipsis = computed(() => {
	return tooManyPages.value && currentPage.value > props.range + 3;
});
const showNextEllipsis = computed(() => {
	return tooManyPages.value && totalPages.value - currentPage.value > props.range + 2;
});

const visiblePageButtons = computed(() => {
	if (!tooManyPages.value) {
		return Array.from({ length: totalPages.value - 2 }, (_, i) => i + 2);
	}

	const secondToLastPage = totalPages.value - 1;
	let start = 2;
	let end = secondToLastPage;

	if (showPreviousEllipsis.value && showNextEllipsis.value) {
		start = currentPage.value - props.range;
		end = currentPage.value + props.range;
	} else {
		if (showPreviousEllipsis.value) {
			start = secondToLastPage - (props.range * 2 + 1);
		}
		if (showNextEllipsis.value) {
			end = props.range * 2 + 3;
		}
	}

	return Array.from({ length: end - start + 1 }, (_, i) => start + i);
});

const rowsPerPageOptions = computed(() => {
	const options = [];
	VALID_ROWS_PER_PAGE.forEach(number => {
		if (number >= props.itemCount) {
			return;
		}
		options.push({ value: number, text: `${number}` });
	});
	options.push({ value: props.itemCount, text: `All (${props.itemCount})` });
	return options;
});

function nextPage() {
	if (currentPage.value < totalPages.value) {
		currentPage.value += 1;
	}
}
function previousPage() {
	if (currentPage.value > 1) {
		currentPage.value -= 1;
	}
}

function handleRowCountChange(newValue) {
	if (newValue && newValue !== props.rowsPerPage) {
		emit('update:rows-per-page', newValue);
	}
}

watchEffect(() => {
	if (currentPage.value > totalPages.value) {
		currentPage.value = totalPages.value;
	}

	if (currentPage.value < 1) {
		currentPage.value = 1;
	}

	jumpToPage.value = currentPage.value;

	const newStart = (currentPage.value - 1) * props.rowsPerPage;
	emit('update:start-index', newStart);

	emit('update:end-index', newStart + Math.min(props.rowsPerPage, props.itemCount));
});
watch(
	[() => props.rowsPerPage, () => props.itemCount],
	([newRowsPerPage, newItemCount], [previousRowsPerPage, previousItemCount]) => {
		if (newItemCount === 0) {
			return;
		}

		const isMinimumRowsPerPage = newRowsPerPage <= 5;
		const isAllSelected =
			newRowsPerPage === previousRowsPerPage && previousRowsPerPage === previousItemCount;
		const hasItemCountChanged = newItemCount !== previousItemCount;
		const hasRowsPerPageExceededItemCount = newItemCount < newRowsPerPage;
		const hasRowsPerPageChanged = newRowsPerPage !== previousRowsPerPage;

		if (isMinimumRowsPerPage) {
			emit('update:rows-per-page', 5);
		} else if (isAllSelected && hasItemCountChanged) {
			emit('update:rows-per-page', newItemCount);
		} else if (hasRowsPerPageExceededItemCount) {
			emit('update:rows-per-page', newItemCount);
		} else if (hasRowsPerPageChanged) {
			emit('update:rows-per-page', newRowsPerPage);
		}
	}
);
defineExpose({
	currentPage,
	totalPages,
	rowsPerPageOptions,

	nextPage,
	previousPage,
});
</script>
<style lang="scss" scoped>
.paginator-button {
	@apply h-9 rounded outline-none ring-orange-500;
	@apply hover:bg-white focus-visible:bg-white focus-visible:ring-2 dark:hover:bg-gray-700 dark:focus-visible:bg-gray-900;
}

.next-previous-button {
	@apply paginator-button w-8;
	@apply aria-disabled:cursor-not-allowed aria-disabled:text-gray-500 aria-disabled:hover:bg-transparent aria-disabled:focus-visible:bg-transparent;
}

.page-button {
	@apply paginator-button w-6;
	&.active {
		@apply bg-gray-800 text-gray-100 dark:bg-gray-100 dark:text-gray-800;
	}
}
</style>

<template>
	<LoadingSpinner
		v-if="loading"
		text="Retrieving domain activity..."
		class="mt-16 text-gray-200"
	/>
	<div v-else class="rounded-md bg-white p-2 dark:bg-gray-800">
		<h3 class="py-2 text-2xl font-semibold text-gray-700 dark:text-gray-100">
			Domain Activity
		</h3>
		<BaseExpansionList :items="activitySections" focusable>
			<template #header="item">
				<div class="flex items-center justify-between gap-2">
					<div class="flex items-center gap-2">
						<DomainProductTypeBadge
							v-if="item.type === 'domain'"
							small
							:domain="item"
						/>
						<span class="ml-2 text-base font-semibold">
							{{ item.name }}
							<span class="text-sm text-gray-500 dark:text-gray-400">
								({{ getServiceUpdates(item.type, item.name).length }} events)
							</span>
						</span>
					</div>
					<div
						v-if="item.type === 'domain'"
						class="mr-2 w-fit rounded-md border px-2 py-1 text-sm font-medium"
						:class="
							item.active
								? 'border-green-300 bg-green-100 text-green-900 dark:border-green-100'
								: 'border-red-200 bg-red-100 text-red-800 dark:border-red-100'
						"
					>
						{{ item.active ? 'Active' : 'Canceled' }}
					</div>
				</div>
			</template>

			<template #content="item">
				<div class="rounded-md p-4 shadow-md">
					<SortableTable
						:items="getServiceUpdates(item.type, item.name)"
						:headers="headers"
						item-unique-key="id"
						initial-sort-by="created"
						:sort-desc="true"
						show-pagination
					>
						<template #item.created="{ created }">
							{{ new Date(created).toLocaleString() }}
						</template>
					</SortableTable>
				</div>
			</template>
		</BaseExpansionList>
	</div>
</template>

<script setup>
import { onMounted, ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import useAgentStore from '@/stores/agent/agent';
import useDomainsStore from '@/stores/agent/agent-domains';
import { useApi } from '@/composables/useApi';
import SortableTable from '@/components/ui/SortableTable.vue';
import BaseExpansionList from '@/components/ui/BaseExpansionList.vue';
import LoadingSpinner from '@/components/ui/LoadingSpinner.vue';
import DomainProductTypeBadge from '@/components/domains/DomainProductTypeBadge';

const agentStore = useAgentStore();
const domainStore = useDomainsStore();
const { movableDomains } = storeToRefs(domainStore);
const loading = ref(true);
const servicesUpdates = ref([]);
const VueRoute = useRoute();

const headers = [
	{ text: 'Updated By', value: 'updated_by', filterable: true, sortable: true },
	{ text: 'IP Address', value: 'ip', filterable: true, sortable: true },
	{ text: 'Date', value: 'created', filterable: true, sortable: true },
	{
		text: 'Stored Description',
		value: 'stored_description',
		filterable: true,
		sortable: true,
		wrap: true,
	},
	{ text: 'Description', value: 'description', filterable: true, sortable: true, wrap: true },
	{ text: 'Service Type', value: 'service_type', filterable: true, sortable: true },
];

const serviceTypes = {
	appointment: {
		filter: update => update.stored_description === 'Appointment Scheduler Subscription',
		name: 'Appointment Scheduler Events',
	},
	callrail: {
		filter: update => update.stored_description === 'Callrail Subscription',
		name: 'Callrail Events',
	},
	adwords: {
		filter: update => update.stored_description.startsWith('AdWords Account #'),
		name: 'Adwords Events',
	},
	domain: {
		filter: (update, domainName) => update.stored_description === domainName,
		name: null,
	},
	other: {
		filter: update => {
			const domainNames = movableDomains.value.map(domain => domain.name);
			return (
				!domainNames.includes(update.stored_description) &&
				!['Appointment Scheduler Subscription', 'Callrail Subscription'].includes(
					update.stored_description
				) &&
				!update.stored_description.startsWith('AdWords Account #')
			);
		},
		name: 'Other Events',
	},
};

function getServiceUpdates(type, domainName) {
	const filteredServiceUpdates = servicesUpdates.value.filter(update =>
		serviceTypes[type].filter(update, domainName)
	);

	if (!filteredServiceUpdates.length) {
		return filteredServiceUpdates;
	}

	const lastServiceUpdate = filteredServiceUpdates.at(-1);

	if (lastServiceUpdate.end_created) {
		const cancellationUpdate = {
			...lastServiceUpdate,
			id: `${lastServiceUpdate.id}_end`,
			updated_by: lastServiceUpdate.end_updated_by,
			description: lastServiceUpdate.end_description,
			ip: lastServiceUpdate.end_ip,
			created: lastServiceUpdate.end_created,
		};
		return [...filteredServiceUpdates, cancellationUpdate];
	}
	return filteredServiceUpdates;
}

const activitySections = computed(() => {
	const domains = movableDomains.value
		.filter(domain => getServiceUpdates('domain', domain.name).length > 0)
		.map(domain => ({ ...domain, type: 'domain' }))
		.sort((a, b) => b.active - a.active);

	const serviceSections = Object.entries(serviceTypes)
		.filter(([key]) => key !== 'domain')
		.map(([key, value]) => ({
			type: key,
			name: value.name,
		}))
		.filter(section => getServiceUpdates(section.type).length > 0);

	return [...domains, ...serviceSections];
});

async function fetchServicesUpdates() {
	await agentStore.ensureAgentData();
	if (!movableDomains.value) {
		await domainStore.getMovableDomains();
	}

	const { data } = await useApi(`api/agents/${VueRoute.params.agentID}/service_updates/`, {
		message: 'Failed to fetch service updates',
	}).json();

	servicesUpdates.value = data.value;
	loading.value = false;
}

onMounted(() => {
	fetchServicesUpdates();
});
</script>

<template>
	<div class="relative inline-block">
		<div
			:class="[
				`text-${productType.badgeColor}-800`,
				`border-${productType.badgeColor}-800`,
				`bg-${productType.badgeColor}-100`,
				small ? 'h-8 w-8 text-sm font-bold' : 'h-14 w-14 text-2xl font-semibold',
			]"
			class="inline-flex items-center justify-center rounded-md border-2 p-1"
		>
			<span>{{ productType.initials }}</span>
		</div>
		<FAIcon
			v-if="locked"
			class="absolute left-0 top-0 block h-3 w-3 -translate-x-2 -translate-y-2 rounded-full bg-green-50 p-1 text-xs text-green-600 ring-2 ring-green-600"
			icon="lock"
		/>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { productTypes } from '@/constants';

const props = defineProps({
	domain: { type: Object, required: true },
	small: { type: Boolean, default: false },
	locked: { type: Boolean, default: false },
});

const productType = computed(() => productTypes[props.domain.product_type]);
</script>

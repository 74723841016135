<template>
	<label :for="labelID" class="group relative flex w-fit cursor-pointer items-start gap-2">
		<input
			:id="labelID"
			v-bind="{ checked, name: name.toString(), disabled, value: modelValue }"
			class="grid appearance-none place-content-center !text-white transition focus-visible:outline-none focus-visible:ring focus-visible:!ring-gray-800/50 focus-visible:!ring-offset-0 group-hover:cursor-pointer disabled:group-hover:cursor-not-allowed"
			:class="
				hideRadioButton
					? 'absolute h-full w-full rounded-sm !bg-transparent !bg-none before:h-0 before:w-0 before:!bg-none'
					: 'h-5 w-5 shrink-0 rounded-full border-2 !border-solid !border-gray-800 before:h-2.5 before:w-2.5 before:scale-0 before:rounded-full before:bg-gray-800 before:transition-transform checked:!border-gray-800 checked:before:scale-100 dark:!border-gray-200 dark:bg-gray-800 dark:before:bg-gray-200'
			"
			type="radio"
			@change="$emit('select')"
			@click="$emit('click')"
		/>

		<span
			class="-mt-0.5"
			:class="disabled ? 'cursor-not-allowed text-gray-400' : 'text-gray-800 dark:text-white'"
		>
			<slot />
		</span>
	</label>
</template>
<script setup>
import { computed } from 'vue';

defineEmits(['select', 'click']);

const props = defineProps({
	modelValue: { type: [String, Boolean], required: true },

	name: { type: [String, Boolean], default: 'radio-button', required: true },
	disabled: { type: Boolean, default: false },
	checked: { type: Boolean, default: false },
	hideRadioButton: { type: Boolean, default: false },
});

const uid = crypto.randomUUID();
const labelID = computed(() => {
	let text = props.modelValue;
	if (typeof props.modelValue === 'string') {
		text = props.modelValue.replaceAll(/\s/g, '_');
	}

	return `radio-option-${text}-${uid}`;
});
</script>
